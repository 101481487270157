import React, { useState, useContext } from "react"
import SearchIcon from '@mui/icons-material/Search';
import { InputBase, Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Link } from 'react-router-dom';
import NoData from "../../components/main/components/noData"
import translate from "../../helpers/translate"
import LanguageContext from "../../helpers/context";
import "./main.css"

const axios = require('axios').default;

export default function Search() {

    const [searchValues, setSearchValues] = useState([])
    const [valuesOpen, setValuesopen] = useState(false)
    const {language} = useContext(LanguageContext)

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '40ch',
                '&:focus': {
                    width: '20ch',
                },
            },
        },
    }));

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    }));


    const search = (e) => {
        let getParams = e.target.value
        if (e.key === 'Enter') {
            axios.post(`${process.env.REACT_APP_API_URL}/search`, { getParams  }, { withCredentials: true })
                .then(response =>
                    setSearchValues(response.data),
                     setValuesopen(true)
                )
        }
    }

    const handleClickAway = () => {
        setValuesopen(false);
    };

    const style = {
        boxShadow: valuesOpen ? 2 : 0
    }

    return (
        <>
            <Search
                sx={style}
            >
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    onKeyPress={search}
                    placeholder={translate(language, "search")}
                    inputProps={{ 'aria-label': 'search' }}
                />

                {valuesOpen ?
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Box className="searchBox" >
                            {searchValues.length > 0 ? searchValues.map((x, index) =>
                                <List key={index}>

                                    <ListItem key={x[index]}>
                                        <ListItemButton
                                            key={x[index]}
                                            component={Link}
                                            to={"/dashboard/" + x.page + "/?id=" + x.id}
                                            onClick={handleClickAway}
                                        >
                                            <ListItemText primary={x.text.split(" ", 12).join(" ") + " ..."} secondary={translate(language, x.page)} key={x[index]} />
                                        </ListItemButton>
                                    </ListItem>
                                </List>) 
                                : 
                                <div className="searchBox flexsearch">{translate(language, "noData" )}</div>
                                }
                        </Box>
                    </ClickAwayListener>
                    : ""}
            </Search>

        </>
    )
}