import React, { useContext} from "react"
import { TableBody, TableCell, TableRow} from '@mui/material';
import translate from "../../../helpers/translate"
import LanguageContext from "../../../helpers/context"

export default function Nodata() {
    const {language} = useContext(LanguageContext)

    return (
        <TableBody>
           <TableRow selected={false} hover={false}>
            <TableCell></TableCell><TableCell></TableCell><TableCell></TableCell><TableCell align="center" variant="body" >{translate(language, "noData")}</TableCell><TableCell></TableCell><TableCell></TableCell><TableCell></TableCell>
            </TableRow>
        </TableBody>
    )
}