
import React, { useState } from "react";
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import Loginpage from './components/login/loginpage';
import Main from "./components/main/main"
import Register from './components/register/register';
import Userprofile from './components/userProfile/userProfile';
import UserGrid from './components/main/components/Users/userGrid';
import Posts from "./components/main/components/Posts/posts";
import Gallery from "./components/main/components/Galerry/gallery";
import PagesGrid from "./components/main/components/Pages/pagesGrid";
import Home from "./components/main/components/Home/home";
import ErrorPage from "./components/main/components/errorPage"

import LanguageContext from "./helpers/context";
import Comments from "./components/main/components/Comments/comments";
import Settings from "./components/main/components/Settings/settings";
import EnterEmail from "./components/renewPassword/enterEmail";
import Emailsent from "./components/renewPassword/emailsent";
import Newpassword from "./components/renewPassword/newpassword";
import Landing from "./components/landing/landing";

export default function App() {
  const [language, setLanguage] = useState("lv-LV")

  return (
    <>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <BrowserRouter>
          <Routes>
            <Route index path="/" element={<Landing />} />
            <Route path="/login" element={<Loginpage />} />
            <Route  path="/dashboard" element={<Main />}>
              <Route path="/dashboard" element={<Navigate to="/dashboard/home" />} />
              <Route path="/dashboard/home" element={<Home />} />
              <Route path="/dashboard/user" element={<Userprofile />} />
              <Route path="/dashboard/users" element={<UserGrid />} />
              <Route path="/dashboard/pages" element={<PagesGrid />} />
              <Route path="/dashboard/posts" element={<Posts />} />
              <Route path="/dashboard/gallery" element={<Gallery />} />
              <Route path="/dashboard/comments" element={<Comments />} />
              <Route path="/dashboard/settings" element={<Settings/>} />
              <Route path="*" element={<ErrorPage/>}/>

            </Route>
            <Route path="/register" element={<Register />} />
            <Route path="/renewPassword" element={<EnterEmail/>}/>
            <Route path="/emailsent" element={<Emailsent/>}/>
            <Route path="/enternewpassword" element={<Newpassword/>} />
          </Routes>
        </BrowserRouter>
      </LanguageContext.Provider>
    </>
  );
}

