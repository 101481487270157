import React, { useState } from "react"
import "./main.css"
import { useParallax } from "react-scroll-parallax";
import octopus from "../assets/octopus2.png"
import axios from "axios"

export default function Main() {
    const parallax = useParallax({
        translateX: [-120, 80],
        translateY: [-120, 300],
        speed: 10
    });

    const [email, setEmail] = useState("")
    const [alertcomp, setAlert] = useState(false)


    const emailchange = (e) => {
        setEmail(e.target.value)
    }

    const submits = (e) => {
        e.preventDefault()

        axios.post("https://api.brevo.com/v3/contacts", { "email": email, "listIds": [2], }, { headers: { "accept": 'application/json', "api-key": process.env.REACT_APP_API_KEY } })
            .then(response => {if(response.data.id){
                setEmail("") 
                setAlert(true)
            }})
    }


    return (
        <section className="landing">

            <img src={octopus} className="octopus" ref={parallax.ref} alt="Octopus" width="200px" height="200px" />
            <h1>Mājas lapu pārvaldība vēl nekad nav bijusi tik viegla!</h1>
            <p>Frogit.lv izstrādās Tavu digitālo produktu, piešķirs pieejas MiniDigital, lai Tu ērti varētu veidot, publicēt un uzglabāt savu saturu.  </p>
            <h3>Atstāj savu epasta adresi un Sandra ar Tevi sazināsies</h3>
            <form onSubmit={submits} className="alertform">
                <input type="email" name="email" onChange={emailchange} required />

                {alertcomp ? <div className="alert">Jūsu e-pasts nosūtīts. Drīz saņemsiet ziņu. Ja nesaņemat to šodien, pārbaudiet <i>spama</i> kastīti.</div> : <button className="button" type="submit" >NOSŪTĪT</button>}
            </form>
        </section>
    )
}