import React, { useState, useEffect, useContext } from "react"
import {
    Box,
    Paper,
    Avatar,
    Badge,
    IconButton,
    OutlinedInput,
    InputLabel,
    FormControl,
    Button,
    Snackbar,
    Alert,
    Select,
    MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";
import "./userProfile.css";
import { useForm } from 'react-hook-form';
import translate from "../../helpers/translate";

import LanguageContext from "../../helpers/context"
import ErrorPage from "../main/components/errorPage";

const FormData = require('form-data');

export default function Userprofile() {

    const { register, handleSubmit} = useForm({ defaultValues: null });

    const [user, setUser] = useState()
    const [file, setFile] = useState()
    const [avatar, setAvatar] = useState()
    const [status, setStatus] = useState()
    const [message, setMessage] = useState()
    const [isSubmitting, setSubmitting] = useState(false)


    const { language, setLanguage } = useContext(LanguageContext)

    useEffect(() => {
        getUser()
    }, []);

    const getUser = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/user`, { withCredentials: true })
            .then((response) => {
                if (response.data) {
                    setUser(response.data)
                    setFile(process.env.REACT_APP_API_URL + response.data[0].file)
                    setLanguage(response.data[0].language)
                }
            })
            .catch((error) => {
                setMessage(error.message)
                setStatus("error")
            });
    }


    if (!user)
        return <ErrorPage/>

    const handleChange = (e) => {
        let img = e.target.files[0];
        setFile(URL.createObjectURL(img))
        setAvatar(img)
    }

    const submit = (data) => {

        setSubmitting(true)
        const formData = new FormData();
        formData.append("avatar", avatar);
        formData.append("email", data.email)
        formData.append('name', data.name)
        formData.append('surname', data.surname)
        formData.append('language', data.language)

        axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
            withCredentials: true, headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((response) => {
                setSubmitting(false)
                setStatus(response.data.status)
                setMessage(response.data.message)
                setLanguage(data.language)
            })
            .catch((error)=>{
                setStatus("error")
                setMessage(error.message)
            })
    }

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Snackbar open={message ? true : false} autoHideDuration={4000} onClose={() => { setMessage() }} anchorOrigin={{ vertical: "top", horizontal: "center" }} >
                <Alert severity={status} sx={{ width: '100%' }} color={status}>
                    {translate(language, message)}
                </Alert>
            </Snackbar>
            <form onSubmit={handleSubmit(submit)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: "center",
                        width: 300,
                        padding: "15px",
                        borderRadius: "10px"
                    }}
                    component={Paper}
                >
                    <Badge
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        badgeContent={
                            <IconButton
                                component="label"
                            >
                                <EditIcon
                                    sx={{ cursor: "pointer" }}
                                    fontSize="small"
                                />
                                <input hidden type="file" name="avatar"
                                    accept="image/png, image/jpeg" onChange={handleChange} action="/upload" />
                            </IconButton>
                        }
                        overlap="circular"

                    >
                        <Avatar
                            src={file ? file : "broken-image.png"}
                            sx={{ width: 95, height: 95 }}
                        />
                    </Badge>
                    <h3>{user[0].name} {user[0].surname}</h3>

                    <FormControl
                        fullWidth
                        margin="dense"
                    >
                        <InputLabel htmlFor="email">{translate(language, "input-email")}</InputLabel>
                        <OutlinedInput
                            id="email"
                            type="text"
                            name="email"
                            label="e-mail"
                            defaultValue={user[0].username ? user[0].username : ""}
                            {...register("email")}
                        />
                    </FormControl>

                    <FormControl
                        fullWidth
                        margin="dense"
                    >
                        <InputLabel htmlFor="name">{translate(language, "input-name")}</InputLabel>
                        <OutlinedInput
                            id="name"
                            type="text"
                            name="name"
                            label="name"
                            defaultValue={user[0].name ? user[0].name : ""}
                            {...register("name")}

                        />
                    </FormControl>

                    <FormControl
                        fullWidth
                        margin="dense"
                    >
                        <InputLabel htmlFor="surname">{translate(language, "input-surname")}</InputLabel>
                        <OutlinedInput
                            id="surname"
                            type="text"
                            name="surname"
                            label="surname"
                            defaultValue={user[0].surname ? user[0].surname : ""}
                            {...register("surname")}

                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        margin="dense"
                        defaultValue={language}
                    >
                        <InputLabel id="language" htmlFor="language">{translate(language, "input-language")}</InputLabel>
                        <Select
                            labelId="language"
                            id="language"
                            label="Language"
                            defaultValue={language ? language :  "lv-LV"}
                            key={language}
                            {...register("language")}
                        >
                            <MenuItem value={"en-EN"}>{translate(language,"language-en")}</MenuItem>
                            <MenuItem value={"lv-LV"}>{translate(language,"language-lv")}</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        sx={{ marginTop: "8px" }}
                        variant="contained"
                    >
                        {translate(language, "button-update")}
                    </Button>
                </Box>
            </form>
        </div>
    )
}