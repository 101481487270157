import React, { useState, useEffect, useContext} from "react"
import {
    Button,
    TablePagination,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Checkbox,
    Alert, Tooltip,
    IconButton,
    TableSortLabel,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import NoData from "../noData";
import AddPost from "./addPost";
import DeleteIcon from '@mui/icons-material/Delete';
import PopConfirm from "../../popConfirm";

import axios from "axios";
import translate from "../../../../helpers/translate";
import LanguageContext from "../../../../helpers/context"
import CellRenderer from "./cellRenderer";
import Error from "../Message"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function Posts() {

    const [selected, setSelected] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false)
    const [posts, setPosts] = useState([])
    const [edit, setEdit] = useState(false)
    const [openPop, setOpenPop] = useState(false)

    const [message, setMessage] = useState(false)
    const [status, setStatus] = useState("success")
    const [messagetext, setMessageText] = useState("Data saved sucesfully")
    const [sortStatus, setSortStatus] = useState()
    const [sortOrder, setSortOrder] = useState("")
    const [coldefs, setColdefs] = useState([])
    const [pages, setPages] = useState()
    const [selectvalue, setSelectvalue] = useState(-1)
    const [filtereditems, setfiltered] = useState([])
    const [selectStatus, setSelectStatussvalue] = useState(-1)
    const [betweenavalue, setbetweenvalue] = useState([])
    const { language } = useContext(LanguageContext)
    const [settings, setSettngs] = useState([])

    const urlParams = new URLSearchParams(window.location.search);
    const id  = urlParams.get('id');
 
    useEffect(() => {
        getData()
        getColdefs()
        getPages()
        getsettings()
    }, [edit, open]);

    const getData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getPosts`, { withCredentials: true })
            .then((response) => {
                setPosts(response.data)
                setfiltered(response.data)
                setbetweenvalue(response.data)
                if(id){
                    response.data.forEach(x => {if(x.id == id){selected.push(x)}})
                     }
                     handleChange(selectvalue)
            })

            .catch((error) => {
                setMessageText("error-msg")
                setStatus("error")
                setMessage(true)
            });
    }

    const getsettings = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getsettings`, { withCredentials: true })
            .then((response) => {
                setSettngs(response.data)
            })
            .catch((error) => {
                setStatus("error")
                setMessage(error)
                setMessageText("error-msg")

            })
    }

    const filteredsettings= settings?.languagelist?.filter(x => x !== settings?.languagemain)

    const getPages = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/pages`, { withCredentials: true })
            .then(response => setPages(response.data))
            .catch((error) => {
                setStatus("error")
                setMessage(true)
                setMessageText("error-msg")
            })
    }

    const getColdefs = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/postColdefs`, { withCredentials: true })
            .then(response => setColdefs(response.data))
            .catch((error) => {
                setStatus("error")
                setMessage(true)
                setMessageText("error-msg")
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleClick = (event, row) => {
        let elem = selected.includes(row)

        setSelected([...selected, row])

        if (elem) {
            let jauns = selected.filter(x => x.id !== row.id)
            setSelected(jauns)
        }
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = (status, message) => {
        setOpen(false)
        setEdit(false)
        setSelected([])

        if (status && message) {
            setMessage(true)
            setStatus(status)
            setMessageText(message)
        }
    }

    const deleteitems = () => {
        setOpenPop(true)
    }

    const closed = () => {
        setOpenPop(false)
        setSelected([])
    }

    const onOk = () => {
        setOpenPop(false)

        axios.delete(`${process.env.REACT_APP_API_URL}/postdelete`, { params: { selected } })
            .then(response => {
                setStatus(response.data.status)
                setMessageText(response.data.message)
                setMessage(true)
                getData()
            })
            .catch((error) => {
                setStatus("error")
                setMessageText(error)
                setMessage(true)
            })
        setSelected([])
    }


    const editPost = (id) => {      
        setSelected(id)  
       setOpen(true)
       setEdit(true)
    }

    const closing = () => {
        setMessage(false)
    }

    const createSortHandler = (field) => {

        if(!settings.languagelist?.includes(field)){

        setSortStatus(field)
        setSortOrder("asc")
        posts.sort((a, b) => a[field].localeCompare(b[field]));

        if (sortStatus === field) {
            if (sortOrder === "asc") {
                setSortOrder("desc")
                posts.sort((a, b) => b[field].localeCompare(a[field]));
            } else {
                setSortOrder("asc")
            }
        }
    }
    }

    const handleChange = (data) => {
        setSelectvalue(data)

        if (data !== -1) {
            let filtereditems = posts.filter(item => item.page === data)
            setfiltered(filtereditems)
            setbetweenvalue(filtereditems)
        }
        setPage(0)
    }

    let count = 0
    const handlestatusChange = (e) => {
        count++
        setSelectStatussvalue(e.target.value)

        if (count > 0 && e.target.value !== -1) {
            let filtered = betweenavalue.filter(item => item.statuss === e.target.value)
            setfiltered(filtered)
        }
    }

    const allvalues = () => {
        count = 0
        if (selectvalue === -1) {
            setfiltered(posts)
        } else { setfiltered(betweenavalue) }
    }

    const allvaluespage = () => {
        count = 0
        setfiltered(posts)
        setbetweenvalue(posts)
    }

    return (
        <>
            {open ? <AddPost close={handleClose} selected={selected} state={edit} /> :
                <>
                    <Error open={message} func={closing} status={status} message={messagetext} />
                    <div style={{ marginBottom: "20px" }} className="toolbar">
                        <h1>{translate(language, "posts")}</h1>
                        <span>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpen}
                            >
                                <AddIcon fontSize="small" sx={{ marginRight: "5px" }} />
                                {translate(language, "add-post")}
                            </Button>
                            <Button
                                style={{ width: "auto" }}
                                name='edit'
                                variant="text"
                                color="primary"
                                disabled={selected.length === 1 ? false : true}
                                onClick={()=>editPost(selected)}
                            >
                                <EditIcon fontSize="small" />
                                {translate(language, "button-edit")}
                            </Button>
                        </span>
                    </div>
                    <div style={{ marginBottom: "20px" }} >
                        <PopConfirm open={openPop} close={closed} onOk={onOk} title={" "} component={translate(language, "delete-items", selected.length)}/>
                        <div style={{height: selected.length >= 1 ? "0px" : "50px" }}/>

                        {selected.length >= 1 && <Alert variant="filled" severity="info" color="info" icon={false} action={
                            <Tooltip title="Delete" placement="top">
                                <IconButton onClick={deleteitems} size="small">
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                        >
                            <b>{selected.length} {translate(language, "selected")}</b></Alert>}
                    </div>
                    <span style={{display:"flex", flexDirection: "row", justifyContent: "flex-start"}}>
                        <FormControl
                            sx={{ m: 2, width: 190 }}
                            size="small"

                        >
                            <InputLabel id="demo-select-small-label">{translate(language, "pages")}</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                label="Pages"
                                value={selectvalue}
                                onChange={(e)=>handleChange(e.target.value)}
                                sx={{ backgroundColor: "white" }}
                            >
                                <MenuItem value={-1} onClick={allvaluespage}>...</MenuItem>

                                {pages?.map(x =>
                                    <MenuItem key={x.id} value={x.id}>{x.Name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 2, width: 190}} size="small" >
                            <InputLabel id="demo-select-small-label">{translate(language, "header-isAdmin")}</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                label="statuss"
                                value={selectStatus}
                                sx={{ backgroundColor: "white" }}
                                onChange={handlestatusChange}
                            >
                                <MenuItem onClick={allvalues} value={-1}>...</MenuItem>
                                <MenuItem value={1}>{translate(language, "tag-published")}</MenuItem>
                                <MenuItem value={0}>{translate(language, "tag-draft")}</MenuItem>

                            </Select>
                        </FormControl>
                    </span>
                    <TableContainer  >
                       <Table >
                            <TableHead >
                                <TableRow >
                                    {coldefs?.map(x =>
                                        <TableCell
                                            key={x.field}
                                            sortDirection={false}
                                        >
                                            <TableSortLabel
                                                active={sortStatus === x.field}
                                                direction={sortStatus === x.field ? sortOrder : "asc"}
                                                onClick={(e) => { if (x.field !== "statuss" & x.field !== "page") createSortHandler(x.field) }}
                                                hideSortIcon={x.field === "statuss" || x.field === "checkbox" || x.field === "page" || x.field == x.headerName ? true : false}
                                            >
                                                {translate(language, x.headerName)}
                                            </TableSortLabel>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            {filtereditems.length > 0 ? <TableBody>
                                {(rowsPerPage > 0
                                    ? filtereditems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : filtereditems).map(x => <TableRow key={x.id} hover sx={{ backgroundColor: "white" }} selected={selected?.includes(x)} onClick={(event) => handleClick(event, x)}  onDoubleClick={(event) => editPost([x])}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                value={x.id}
                                                checked={selected.includes(x)}
                                            />
                                        </TableCell>

                                        <TableCell >{x.title}</TableCell>
                                        <TableCell >{x.text.substring(0,120).concat("...")}</TableCell>
                                        <TableCell >{x.author}</TableCell>
                                        <TableCell >{x.createDate === null ? "" : new Date(x.createDate).toLocaleDateString()}</TableCell>
                                        <TableCell>{x.statuss === 1 ? <span className="tag-green">{translate(language, "tag-published")}</span> : <span className="tag-red">{translate(language, "tag-draft")}</span>}</TableCell>
                                        <TableCell>{<CellRenderer pageId={x.page} pages={pages} />}</TableCell>
                                        { filteredsettings?.map(y => <TableCell key={y}>{x.languages?.includes(y) ?  <CheckCircleOutlineIcon color="success" /> : <HighlightOffIcon color="error" />}</TableCell>)}
                                    </TableRow>)}
                            </TableBody> : <NoData />}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filtereditems.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={translate(language, "rows-per-page")}

                    />
                </>}
        </>

    )
}