import React, { useContext, useState, useEffect } from "react"
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Checkbox,
    TablePagination,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    Alert,
    Tooltip,
    IconButton,
} from '@mui/material';
import translate from "../../../../helpers/translate"
import LanguageContext from "../../../../helpers/context"
import NoData from "../noData";
import axios from "axios";
import Message from "../Message";
import DeleteIcon from '@mui/icons-material/Delete';
import PopConfirm from "../../popConfirm";


export default function Comments() {
    const { language } = useContext(LanguageContext)
    const [colDefs, setColdefs] = useState([])
    const [comments, setComments] = useState([])
    const [status, setStatus] = useState("success")
    const [message, setMessage] = useState()
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [posts, setPosts] = useState()
    const [filteredata, setFilteredData] = useState([])
    const [selectvalue, setSelectvalue] = useState("none")
    const [selected, setSelected] = useState([])
    const [openPop, setOpenPop] = useState(false)
  

    useEffect(() => {
        getColdefs()
        getData()
        getPages()
    }, []);



    const getColdefs = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/commentColdefs`)
            .then(response => setColdefs(response.data))
            .catch((error) => {
                setStatus("error")
                setMessage(error)
            })
    }

    const getData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/comments`, { withCredentials: true })
            .then(response => {
                setComments(response.data)
                setFilteredData(response.data)
            }
            )
            .catch((error) => {
                setStatus("error")
                setMessage(error)
            })
    }


    const getPages = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getPosts`, { withCredentials: true })
            .then(response => {
                setPosts(response.data)
            })
            .catch((error) => {
                setStatus("error")
                setMessage(error)
            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closemessage = () => {
        setMessage()
    }

    const allvaluespage = () => {
        setFilteredData(comments)
    }

    const handleChange = (e) => {
        setSelectvalue(e.target.value)

        if (e.target.value !== "none") {
            let filtereditems = comments.filter(item => item.title === e.target.value)
            setFilteredData(filtereditems)
        }
    }

    const deleteitems = () => {
        setOpenPop(true)
    }

    const selectrow = (row) => {
        let elem = selected.includes(row)

        setSelected([...selected, row])

        if (elem) {
            let jauns = selected.filter(x => x.id !== row.id)
            setSelected(jauns)
        }
    }

    const onOk = () => {
        setOpenPop(false)

        axios.delete(`${process.env.REACT_APP_API_URL}/commentdelete`, { params: { selected } })
            .then(response => { 
                if (response.data === "Ok") {
                   getData()
                    setMessage(translate(language, "success-msg"))
                    setStatus("success")
                }
            })
            .catch((error) => {
                setStatus("error")
                setMessage(error.message)
            })
        setSelected([])
    }

    const closed = () => {
        setOpenPop(false)
        setSelected([])
    }

    return (<>
        <Message open={message ? true : false} message={message} status={status} func={closemessage} />
        <div style={{ marginBottom: "20px" }} className="toolbar">
            <h1>{translate(language, "comments")}</h1>
        </div>
        <div style={{ height: selected.length >= 1 ? "0px" : "50px" }} />
        <PopConfirm open={openPop} close={closed} onOk={onOk} title={""} component={translate(language, "delete-items", selected.length)} />

        {selected.length >= 1 && <Alert variant="filled" severity="info" color="info" icon={false} action={
            <Tooltip title="Delete" placement="top">
                <IconButton onClick={deleteitems} size="small">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        }
        >
            <b>{selected.length} {translate(language, "selected")}</b></Alert>}
        <FormControl
            sx={{ m: 2, width: 190 }}
            size="small"

        >
            <InputLabel id="demo-select-small-label">{translate(language, "posts")}</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Pages"
                size="small"
                value={selectvalue}
                onChange={handleChange}
                sx={{ backgroundColor: "white" }}
            >
                <MenuItem value={"none"} onClick={allvaluespage}>...</MenuItem>

                {posts?.map(x =>
                    <MenuItem key={x.id} value={x.title}>{x.title}</MenuItem>
                )}
            </Select>
        </FormControl>

        <TableContainer  >
            <Table >
                <TableHead >
                    <TableRow >
                        {colDefs?.map(x =>
                            <TableCell
                                key={x.field}
                                sortDirection={false}
                            >
                                {translate(language, x.headerName)}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                {filteredata.length > 0 ? <TableBody>

                    {(rowsPerPage > 0
                        ? filteredata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : filteredata).map(x => <TableRow key={x.id} hover sx={{ backgroundColor: "white" }} selected={selected?.includes(x)} onClick={() => { selectrow(x) }}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    value={x.id}
                                    checked={selected.includes(x)} 
                                />
                            </TableCell>
                            <TableCell >{x.author}</TableCell>
                            <TableCell >{x.comment}</TableCell>
                            <TableCell >{x.title}</TableCell>
                            <TableCell>{x.answerTo}</TableCell>
                            <TableCell >{x.created === null ? "" : new Date(x.created).toLocaleString()}</TableCell>
                        </TableRow>)}
                </TableBody> : <NoData />}
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredata.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={translate(language, "rows-per-page")}
        />
    </>
    )
}