import React from "react"
import logo from "../../assets/logo.png"
import "./header.css"

export default function Header(){

    return(
        <header>
            <img src={logo} width="80px" height="auto" alt="minidigital logo"/>
            <button className="button"><a href="/login">Pieslēgties</a></button>
        </header>
    )
}