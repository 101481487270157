import React from "react"
import video from "../../assets/attīstība tavam biznesam.mp4"

export default function Video() {

    return (
        <section>
            <video width="900" height="500" controls>
                <source src={video} type="video/mp4" />
                <source src="mov_bbb.ogg" type="video/ogg" />
            </video>
        </section>
    )
}