import React, { useContext } from "react"
import image from "../../../assets/error.png"
import LanguageContext from "../../../helpers/context"

export default function ErrorPage() {
    const { language } = useContext(LanguageContext)

    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <img
                src={image} 
                style={{borderRadius: "14px"}}
                />
        </div>
    )
}