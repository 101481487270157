import React, {useContext} from "react"
import {
    Popover,
    MenuItem, 
    ListItemIcon,
    ListItemText 
  } from '@mui/material';
  import translate from "../../../helpers/translate"
  import LanguageContext from "../../../helpers/context"
  import DeleteIcon from '@mui/icons-material/Delete';

export default function Poppover ({open, anchorEl, handleClose, menufunc, data }){
    const {language} = useContext(LanguageContext)

    return (
        <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{
          sx: {
            p: 0,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem  onClick={menufunc}>
        <ListItemIcon>
            <DeleteIcon fontSize="small"/>
        </ListItemIcon>
         <ListItemText> {translate(language, "button-delete", data)}</ListItemText>
        </MenuItem>
      </Popover>
    )
}