import React, { useState, useContext } from "react";
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button, Snackbar, Alert, FormHelperText } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm } from "react-hook-form";
import translate from "../../helpers/translate";
import LanguageContext from "../../helpers/context"
import { useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const axios = require('axios').default;

export default function Form({ domain, close }) {
    const { register, handleSubmit } = useForm({ defaultValues: null });

    const [showPassword, setShowPassword] = useState(false);
    const [validemail, setValidemail] = useState();
    const [isSubmitting, setSubmitting] = useState(false)
    const { language } = useContext(LanguageContext)
    const navigate = useNavigate();
    const [status, setStatus] = useState("success")
    const [message, setMessage] = useState()
    const [validpass, setValidpass] = useState()
  

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const emailValid = (emails) => {
        let req = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
        const validemail = req.test(emails)
        setValidemail(!validemail)
    }

    const passwordStrength = (password) => {

        let set = password.split("")
        if (set.length >= 8) {
            setValidpass(true)
        } else {
            setValidpass(false)
        }
    }

    const handleRegistration = (data) => {
        setSubmitting(true)
        emailValid(data.email)
        if (domain) {
            data.Domain = domain
            data.fromDashboard = true
        } else {
            data.isAdmin = true
        }
        if(!validpass){setSubmitting(false)}
        if(validpass){axios.post(`${process.env.REACT_APP_API_URL}/create`, { data }, { withCredentials: true })
            .then((response) => {
                setSubmitting(false)
                if(response.data.error === 104 ) {
                    close(response.data)
                }
                setMessage(response.data.message)
                setStatus((response.data.error === 103 || response.data.error === 102) ? "error" : "success")
                if (!data.fromDashboard && response.data.error === 101) {
                    setTimeout(() => {
                        navigate("/");
                    }, 2000);
                }
            })
            .catch((error) => {
                close && close(error)
                setMessage(error.message)
                setStatus("error")
            });
        }
    }

    return (
        <>
            <Snackbar open={message ? true : false} autoHideDuration={4000} onClose={() => { setMessage() }} anchorOrigin={{ vertical: "top", horizontal: "center" }} >
                <Alert severity={status} sx={{ width: '100%' }} color={status}>
                    {translate(language, message)}
                </Alert>
            </Snackbar>
            <form onSubmit={handleSubmit(handleRegistration)}>
                <h1>{translate(language, "account-create")}</h1>
                <FormControl
                    fullWidth
                    margin="normal"
                    size="small"
                    required
                >
                    <InputLabel htmlFor="outlined-adornment-email-login">{translate(language, "email-login")}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-email-login"
                        type="email"
                        name="email"
                        label={translate(language, "email-login")}
                        inputProps={{ maxLength: 102 }}
                        {...register("email", {
                            onChange: (e) => { emailValid(e.target.value) },
                        })}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    margin="normal"
                    size="small"
                    error={typeof validpass === "boolean" ? !validpass : false}
                    required
                >
                    <InputLabel htmlFor="outlined-adornment-password-login">{translate(language, "password-login")}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password-login"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        inputProps={{ maxLength: 102 }}
                        {...register("password", {
                            onChange: (e) => { passwordStrength(e.target.value) },
                        })}
                        endAdornment={
                            <InputAdornment position="end">
                                {validpass ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="large"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>

                            </InputAdornment>
                        }
                        label={translate(language, "password-login")}
                    />
                    <FormHelperText id="component-helper-text">
                        Parolei jāsatur vismaz 8 simboli
                    </FormHelperText>
                </FormControl>

                <FormControl
                    fullWidth
                    margin="normal"
                    size="small"
                    required
                >
                    <InputLabel htmlFor="outlined-adornment-name-login">{translate(language, "input-name")}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-name-login"
                        type="text"
                        name="Name"
                        label={translate(language, "input-name")}
                        inputProps={{ maxLength: 102 }}
                        {...register("Name")}
                    />
                </FormControl>

                <FormControl
                    fullWidth margin="normal"
                    size="small"
                    required
                >
                    <InputLabel htmlFor="outlined-adornment-surname-login">{translate(language, "input-surname")}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-surname-login"
                        type="text"
                        name="Surname"
                        label={translate(language, "input-surname")}
                        inputProps={{ maxLength: 102 }}
                        {...register("Surname")}
                    />
                </FormControl>

                <FormControl
                    fullWidth margin="normal"
                    size="small"
                    required
                >
                    <InputLabel htmlFor="outlined-adornment-domain-login">{translate(language, "input-domain")}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-domain-login"
                        type="text"
                        name="Domain"
                        label={translate(language, "input-domain")}
                        disabled={domain ? true : false}
                        defaultValue={domain ? domain : null}
                        inputProps={{ maxLength: 102 }}
                        {...register("Domain")}

                    />
                </FormControl>

                <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    margin="normal"
                >
                    {translate(language, "submit-continue")}
                </Button>
            </form>
        </>

    )
}