import React, { useState, useEffect, useContext } from "react"
import {
    Button,
} from '@mui/material';

import "./gallery.css"
import Cardphoto from "./cardPhoto";
import AddGallery from "./addGallery";
import translate from "../../../../helpers/translate";
import LanguageContext from "../../../../helpers/context"
import Error from "../Message"

const axios = require('axios').default;

export default function Gallery({ }) {

    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState([])
    const [gallerry, setGallerry] = useState([])
    const [status, setStatus] = useState()
    const [message, setMessage] = useState()
    const { language } = useContext(LanguageContext)


    useEffect(() => {
        getData()
    }, []);

    const openadd = () => {
        setOpen(true)
    }

    const closeadd = (message, status) => {

        setOpen(false)
        setEdit([])
        if (typeof message === "string") {
            setMessage(message)
        }
        if(status != undefined){
            setStatus(status)
        }
        getData()
    }

    const editopen = (id, title) => {
        const obj = {
            id: id,
            title: title
        }
        setEdit(obj)
        setOpen(true)
    }

    const getData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/galleriesget`, { withCredentials: true })
            .then(response => setGallerry(response.data))
    }

    const close =()=>{
        setMessage()
    }

    return (
        <>
      
         <Error status={status} message={message} open={message ? true : false} func={close}/>
            {open ? <AddGallery close={closeadd} data={edit} /> :
                <>
                    <div style={{ marginBottom: "20px" }} className="toolbar">
                        <h1>{translate(language, "gallery")}</h1>
                        <span>
                            <Button
                                variant="contained"
                                name='add'
                                color="primary"
                                onClick={openadd}
                            >
                                {translate(language, "new-gallery")}
                            </Button>
                        </span>
                    </div>

                    <div className="cards">
                        {gallerry && gallerry.map(x => 
                        <Cardphoto 
                        edit={editopen} 
                        key={x.id} 
                        id={x.id} 
                        src={ x?.files[0]?.file } 
                       title={x.title} 
                        date={x.created} 
                        getdata={getData} 
                        />
                        ) }
                    </div>

                </>
            }
        </>
    )
}