import React, { useContext, useEffect, useState } from "react"
import LanguageContext from "../../../../helpers/context"
import translate from "../../../../helpers/translate";
import axios from "axios"
import "./settings.css"

import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Button,
    MenuItem,
    Select,
    FormControl
} from '@mui/material';
import { useForm } from "react-hook-form";
import Message from "../Message";


export default function Settings() {
    const { language } = useContext(LanguageContext)
    const [languages, setLanguages] = useState([])
    const [selectvalue, setSelectvalue] = useState('LV')
    const [enablemulti, setEnablemulti] = useState(false)
    const [selectedlanguages, setSelectedlanguages] = useState([])
    const [message, setMessage] = useState()
    const [status, setStatus] = useState("success")

    const { register, handleSubmit, errors, reset } = useForm({});
   
    useEffect(() => {
        getLanguagelist()
        init()
    }, [])

    const init = async() => {
      await  axios.get(`${process.env.REACT_APP_API_URL}/getsettings`, { withCredentials: true })
            .then((response) => {
                setSelectedlanguages(response.data.languagelist ? response.data.languagelist : [])
                setEnablemulti(response.data.enablemulti === 1 ? true : false)
                setSelectvalue(response.data.languagemain ? response.data.languagemain : 'LV')
            }
            )
    }
    const getLanguagelist = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getLanguageslist`)
            .then(response => setLanguages(response.data))
    }

    const handleChange = (e) => {
        setEnablemulti(e.target.checked)
    }

    const save = (data) => {
            data.languagemain = selectvalue
            data.enablemulti = enablemulti

            if(data.languagelist === false && data.enablemulti === true ){
            setMessage("settings-valid")
            setStatus("error")
            return 
        }

        axios.post(`${process.env.REACT_APP_API_URL}/setsettings`, { data }, { withCredentials: true })
            .then(response => {
                setMessage(response.data.message)
                setStatus(response.data.status)
            })
    }

    const closemessage = () => {
        setMessage()
    }

    const setmainlang = (e) => {
        setSelectvalue(e.target.value)
    }


    return (
        <>
            <Message open={message ? true : false} func={closemessage} message={message} status={status} />
            <div style={{ marginBottom: "20px" }} className="toolbar">
                <h1>{translate(language, "settings")}</h1>
            </div>
            <form onSubmit={handleSubmit(save)}>
                <div style={{ marginBottom: "20px" }} className="toolbar">

                    <Button
                        variant="contained"
                        sx={{ marginRight: "20px" }}
                        color="primary"
                        type="submit"
                    >
                        {translate(language, "button-save")}
                    </Button>
                </div>
                <Box sx={{ padding: "15px", boxShadow: '1', borderRadius: "20px", backgroundColor: "white", width: "40%" }}>

                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleChange}
                                checked={enablemulti}
                            />}
                        label={translate(language, "settings-enable")}
                        labelPlacement="start"
                        key={enablemulti}
                        {...register("enablemulti")}
                    />
                    <span className="select">
                        <FormControl
                            sx={{ m: 1, width: 190 }}
                            size="small"
                            defaultValue={selectvalue}
                        >
                            <label htmlFor="demo-select-small" >{translate(language, "mainlanguage")}</label>
                            <Select
                                id="demo-select-small"
                                inputProps={{ 'aria-label': 'Without label' }}
                                size="small"
                                defaultValue={selectvalue}
                                key={selectvalue}
                                onChange={setmainlang}
                                sx={{ backgroundColor: "white" }}
                            >
                                {languages?.map(x =>
                                    <MenuItem key={x.value} value={x.value}>{translate(language, x.value)}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </span>
                    {enablemulti &&
                        <>
                            <h3>{translate(language, "choose-lang")}</h3>
                            <FormGroup>
                                {languages.map(x =>

                                    <FormControlLabel
                                        value={x.value}
                                        control={<Checkbox
                                            defaultChecked={selectedlanguages.includes(x.value)}
                                        />}
                                        label={translate(language, x.value)}
                                        labelPlacement="end"
                                        key={x.language}
                                        sx={{flexDirection: "row"}}
                                        {...register("languagelist")}
                                    />
                                    
                                )}
                            </FormGroup>
                        </>}
                </Box>
            </form>
        </>
    )
}