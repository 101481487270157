import React from "react"
import logo from "../../assets/logo.png"
import frogit from "../../assets/frogit.png"
import "./footer.css"

export default function Footer(){

    return(
        <footer>
            <img src={logo} width="150px" height="auto" alt="minidigital logo"/>
            <img src={frogit} width="150px" height="auto" alt="frogit logo"/>
        </footer>
    )
}