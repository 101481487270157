import React, { useContext, useState } from "react"
import image from "../../assets/logo.png"
import translate from "../../helpers/translate";
import LanguageContext from "../../helpers/context"
import { FormControl, InputLabel, OutlinedInput, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Error from "../main/components/Message"
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function EnterEmail() {
    const { language, setLanguage } = useContext(LanguageContext)
    const [emailvalue, setEmail] = useState()
    const axios = require('axios').default;
    const navigate = useNavigate();
    const [message, setMessage] = useState()
    const [status, setStatus] = useState("success")


    const theme = createTheme({
        palette: {
            primary: {
                main: "#673AB7",
            },
            info: {
                main: "#eee7f6",
            },
        }
    });


    const email = (e) => {
        setEmail(e.target.value)
    }

    const send = (e) => {
        e.preventDefault();

        axios.post(`${process.env.REACT_APP_API_URL}/sendmail`, { emailvalue }, { withCredentials: true })
            .then((response) => {

                if (typeof response.data === "object") {
                    setMessage(response.data.message)
                    setStatus(response.data.status)
                } else {
                    navigate("/emailsent", emailvalue)
                }
            })
            .catch((error) => {
                setMessage(error)
                setStatus("error")
            });
    }



    return (
        <ThemeProvider theme={theme}>
            <div className="mainpage">
                <Error status={status} message={message} open={message ? true : false} />

                <div className="login-window">
                    <div className="logo"  >
                        <img
                            src={image}
                            width="50%"
                            alt="logo"
                        />
                    </div>
                    <h1>{translate(language, "login-forgot")}</h1>
                    <form
                        onSubmit={send}
                    >
                        <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="outlined-adornment-email-login">{translate(language, "email-login")}</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                value={emailvalue ? emailvalue : ""}
                                name="email"
                                onChange={email}
                                label="Email Address"
                            />
                        </FormControl>

                        <FormControl margin="normal" fullWidth>
                            <Button
                                disableElevation
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                margin="normal"
                                color="primary"
                            >
                                {translate(language, "send-link")}
                            </Button>
                        </FormControl>
                    </form>
                </div>
            </div>
        </ThemeProvider>
    )
}