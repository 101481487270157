import langjson from "../helpers/language.json"

export default function translate (lang, string, vars){
if(!lang){
    lang = "lv-LV"
}

if(langjson[string] == undefined || langjson[string][lang] == undefined){
    return string
}

let text = langjson[string][lang].includes("{0}")

if(text){
return langjson[string][lang].replace("{0}", vars)
}

return langjson[string][lang]
}

