import React, { useContext, useState, useEffect } from "react"
import {
    Button,
    Box,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    FormControl,
    Alert
} from '@mui/material';

import translate from "../../../../helpers/translate";
import LanguageContext from "../../../../helpers/context"
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";
import PopConfirm from "../../popConfirm";

import { useForm } from "react-hook-form";

export default function ImageModal({ open, close, image, imagesrc }) {
    const [isSubmitting, setSubmitting] = useState(false)
    const [file, setFile] = useState()
    const [opened, setOpen] = useState(false)
    const { language } = useContext(LanguageContext)

    const { register, handleSubmit, errors, reset } = useForm({});


    useEffect(() => {
        getData()
    }, [image])

    const getData = () => {
        const data = image
        if (image) {
            axios.get(`${process.env.REACT_APP_API_URL}/getfilebyid`, { params: { data } })
                .then(response => setFile(response.data))
        } else {
            setFile([imagesrc])
        }

    }

    if (!file) {
        return <div />
    }

    const popconfirm = () => {
        setOpen(true)
    }

    const closepopconfirm = () => {
        setOpen(false)
    }

    const onOk = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}/filedelete`, { params: { file } })
            .then(response => {
                closepopconfirm()
                close("dzests")
            })
    }

    const save = (data) => {

        setSubmitting(true)
        data.id = image

           axios.put(`${process.env.REACT_APP_API_URL}/updatefile`, { data }, { withCredentials: true })
                .then(response =>
                    close(response.data),
                    setSubmitting(false))
                .catch((error) => {
                    setSubmitting(false)
                })
        
    }

    return (
        <>
            <PopConfirm
                open={opened}
                close={closepopconfirm}
                onOk={onOk}
                component={translate(language, "question-delete")}
            />
            <Dialog
                maxWidth={"600px"}
                open={open}
                onClose={()=>close("close")}
            >
                <DialogTitle >
                    <IconButton
                        aria-label="close"
                        onClick={()=>close("close")}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box
                        sx={{ display: "flex", flexDirection: "row" }}>
                        <img src={file[0]?.id !== undefined ? `${process.env.REACT_APP_API_URL}/uploads/${file[0]?.file}` : file[0]?.file} className="modal-item" />
                        <Box
                            sx={{
                                backgroundColor: " hsl(203, 31%, 95%)",
                                padding: "20px",
                                borderRadius: "20px"
                            }}>
                            <h1>{translate(language, "image-modal")}</h1>
                            {file[0].id ? <> <form style={{ display: "flex", flexDirection: "column", width: "300px" }}
                                onSubmit={handleSubmit(save)}
                            >
                                <FormControl
                                    fullWidth
                                    margin="normal"
                                    required
                                    sx={{ backgroundColor: "white" }}
                                >
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={translate(language, "alternate-text")}
                                        multiline
                                        maxRows={4}
                                        size="small"
                                        defaultValue={file ? file[0]?.altext : ""}
                                        {...register("alt")}
                                    />
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    margin="normal"
                                    required
                                    sx={{ backgroundColor: "white" }}
                                >
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={translate(language, "header-title")}
                                        multiline
                                        maxRows={4}
                                        size="small"
                                        defaultValue={file ? file[0]?.title : ""}
                                        {...register("name")}
                                    />
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    margin="normal"
                                    required
                                    sx={{ backgroundColor: "white" }}
                                >
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={translate(language, "image-description")} multiline
                                        maxRows={4}
                                        size="small"
                                        defaultValue={file ? file[0]?.description : ""}
                                        {...register("description")}
                                    />
                                </FormControl>
                                <br></br>
                                <TextField
                                    disabled
                                    type="text"
                                    name="id"
                                    id="outlined-multiline-flexible"
                                    size="small"
                                    value={image}
                                />
                                <br></br>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    margin="normal"
                                >
                                    {translate(language, "submit-continue")}
                                </Button>
                            </form> 
                            <br></br>
                           <Button
                                type="submit"
                                margin="normal"
                                color="error"
                                fullWidth
                                size="small"
                                onClick={popconfirm}
                            >
                                {translate(language, "delete-permanently")}
                            </Button> 
                            </>
                            : <Alert severity="error">{translate(language, "file-edit")}</Alert>}
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}