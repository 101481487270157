import React, { useContext, useState, useEffect } from "react"
import {
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableContainer,
    TableBody,
    Box,
    ImageList,
    ImageListItem,

} from '@mui/material';
import NoData from "../noData"
import CellRenderer from "../Posts/cellRenderer"
import ImageModal from "../Galerry/imageModal"
import axios from "axios"
import translate from "../../../../helpers/translate";
import LanguageContext from "../../../../helpers/context"
import Error from "../Message"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function Home() {
    const [data, setdata] = useState([])
    const [coldefs, setColdefs] = useState([])
    const [pages, setPages] = useState()
    const [files, setFiles] = useState([])
    const [imagemodal, setImageModal] = useState()
    const [image, setImage] = useState()
    const [status, setStatus] = useState()
    const [message, setMessage] = useState()
    const [openError, setErroropen] = useState(false)
    const [settings, setSettngs] = useState([])

    const { language } = useContext(LanguageContext)


    useEffect(() => {
        getData()
        getColdefs()
        getPages()
        getfiles()
        getsettings()
    }, []);

    const getsettings = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getsettings`, { withCredentials: true })
            .then((response) => {
                setSettngs(response.data)
            })
            .catch((error) => {
                setStatus("error")
                setMessage("error-msg")
            })
    }
    const getColdefs = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/postColdefs`, { withCredentials: true })
            .then(response => setColdefs(response.data))
    }

    const getData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getPosts`, { withCredentials: true })
            .then((response) => {
                setdata(response.data)
            })
            .catch((error) => {
                setStatus("error")
                setMessage(error)
                setErroropen(true)
            })
    }

    const getPages = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/pages`, { withCredentials: true })
            .then(response => setPages(response.data))
            .catch((error) => {
                setStatus("error")
                setMessage(error)
                setErroropen(true)
            })
    }


    const getfiles = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getlatestFiles`, { withCredentials: true })
            .then(response => setFiles(response.data))
            .catch((error) => {
                setStatus("error")
                setMessage(error)
                setErroropen(true)
            })
    }

    const openImage = (e) => {
        setImage(e.target.id)
        setImageModal(true)

        return
    }

    const handleClose = (error) => {

        if (error.message) {
            setImageModal(false)
            setStatus(error.status)
            setMessage(error.message)
            setErroropen(true)
            getfiles()
        } else {
            setStatus()
            setMessage()
            setErroropen(false)
            setImageModal()
            getfiles()
        }
    }

    const closing = () => {
        setMessage()
        setErroropen(false)
    }

    const setter = coldefs.filter((value, index) => { return index !== 0 })
    const filteredData = data.sort((a, b) => b.createDate.localeCompare(a.createDate))
    const lastFive = filteredData.filter((value, index) => index < 5)
    const filesfiltered = files.sort((a, b) => b.id - a.id)
    const latestfiles = filesfiltered.filter((value, index) => index < 5)
    const filteredsettings= typeof settings.languagelist !== "string"  ? settings?.languagelist?.filter(x => x !== settings?.languagemain) : []

    return (
        <>
            <Error status={status} message={message} open={openError} func={closing} />

            <div style={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ padding: "15px", boxShadow: '1', borderRadius: "20px", backgroundColor: "white" }}>
                    <div className="toolbar">
                        <h1 >{translate(language, "latest-files")}</h1>
                    </div>
                    <ImageList variant="standart"
                        sx={{
                            gridTemplateColumns:
                                "repeat(auto-fill, minmax(150px, 1fr)) !important"
                        }}
                        gap={12}
                        rowHeight={164}
                    >
                        {latestfiles.map((item, index) =>
                            <ImageListItem
                                key={item.id}
                                onClick={openImage}
                                sx={{ cursor: "pointer" }}
                            >
                                <img
                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.file}`}
                                    loading="lazy"
                                    className="item"
                                    id={item.id}
                                    alt={item.altext}

                                />
                            </ImageListItem>
                        )}
                    </ImageList>

                </Box>

                <Box>
                    <div className="toolbar">
                        <h1>{translate(language, "latest-posts")}</h1>
                    </div>
                    <TableContainer  >
                        <Table >
                            <TableHead >
                                <TableRow >
                                    {setter?.map((key, x) =>
                                        <TableCell
                                            key={x}
                                        >
                                            {translate(language, key.headerName)}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            {lastFive.length > 0 ? <TableBody>
                                {lastFive.map(x => <TableRow key={x.id} hover sx={{ backgroundColor: "white" }} >

                                    <TableCell >{x.title}</TableCell>
                                    <TableCell >{x.text.split(" ", 20).join(" ") + " ..."}</TableCell>
                                    <TableCell >{x.author}</TableCell>
                                    <TableCell >{x.createDate === null ? "" : new Date(x.createDate).toLocaleDateString()}</TableCell>
                                    <TableCell>{x.statuss === 1 ? <span className="tag-green">{translate(language, "tag-published")}</span> : <span className="tag-red">{translate(language, "tag-draft")}</span>}</TableCell>
                                    <TableCell>{<CellRenderer pageId={x.page} pages={pages} />}</TableCell>
                                    { filteredsettings?.map(y => <TableCell key={y}>{x.languages?.includes(y) ?  <CheckCircleOutlineIcon color="success" /> : <HighlightOffIcon color="error" />}</TableCell>)}

                                </TableRow>)}
                            </TableBody> : <NoData />}
                        </Table>
                    </TableContainer>
                </Box>

                {imagemodal && <ImageModal
                    open={imagemodal}
                    close={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    image={image}
                />}
            </div>
        </>
    )
}