import React, {useContext} from "react"
import image from "../../assets/logo.png"
import translate from "../../helpers/translate";
import LanguageContext from "../../helpers/context"

export default function Emailsent() {
    const { language, setLanguage } = useContext(LanguageContext)

    return (
        <div className="mainpage">
            <div className="login-window">
                <div className="logo"  >
                    <img
                        src={image}
                        width="50%"
                        alt="logo"
                    />
                </div>
                <h1>{translate(language, "email-sent")}</h1>
            </div>
        </div>
    )
}