import React, { useContext, useEffect } from "react"
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import translate from "../../helpers/translate"
import LanguageContext from "../../helpers/context"
import logo from "../../assets/mini.png"

export default function Sidebar({ menu }) {

    const location = window.location.pathname
    const { language } = useContext(LanguageContext)

    return (
        <>
        <div id="logo" style={{padding: "10px 15px 5px 15px"}}>
            <img 
            src={logo}
            width="150px"
            height="70px"/>
        </div>
            {menu?.map(item =>
                <ListItemButton
                    value={item.path}
                    selected={location === item.path}
                    component={ Link} // <-- pass Link as component
                    to={item.path}
                    key={item.page}
                >
                    <ListItemText primary={translate(language, item.page)} />
                </ListItemButton>
            )}
        </>
    )
}