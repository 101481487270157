import React, { useState, useEffect, useContext } from "react"
import axios from "axios";
import {
    TablePagination,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableContainer,
    Button,
    Checkbox,
    Alert,
    IconButton,
    Tooltip,
    TableSortLabel,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Snackbar
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddPageModal from "./addPage";
import NoData from "../noData"
import PopConfirm from "../../popConfirm.jsx"
import translate from "../../../../helpers/translate";
import LanguageContext from "../../../../helpers/context"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useParams } from "react-router-dom";

import "./pagesGrid.css"
export default function PagesGrid() {

    const [data, setData] = useState([])
    const [selected, setSelected] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false)
    const [operation, setOperation] = useState("")
    const [openPop, setOpenPop] = useState(false)
    const [coldefs, setColdefs] = useState([])
    const [selectvalue, setSelectvalue] = useState(-1)
    const [filtereditems, setFilteredData] = useState([])
    const [message, setMessage] = useState()
    const [status, setStatus] = useState("success")
    const [error, setError] = useState(false)
    const { language } = useContext(LanguageContext)
    const [settings, setSettngs] = useState([])

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    useEffect(() => {
        getData()
        getColdefs()
        getsettings()

    },[])


    const getsettings = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getsettings`, { withCredentials: true })
            .then((response) => {
                setSettngs(response.data)
            })
            .catch((error) => {
                setStatus("error")
                setMessage("error-msg")
            })
    }

    const getData = () => {

        axios.get(`${process.env.REACT_APP_API_URL}/pages`, { withCredentials: true })
            .then(response => {
                setData(response.data)
                setFilteredData(response.data)
                if (id) {
                    response.data.forEach(x => { if (x.id == id) { selected.push(x) } })
                }
            })
            .catch((error) => {
                setStatus("error")
                setMessage(error)
                setError(true)
            })
    }

    const getColdefs = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/pageColdefs`, { withCredentials: true })
            .then(response =>
                setColdefs(response.data))
            .catch((error) => {
                setStatus("error")
                setMessage(error)
                setError(true)
            })
    }

    const handleClose = (data) => {

        if (data) {
            setStatus(data.status)
            setMessage(data.message)
            setError(true)
            getData()
        } else {
            setStatus()
            setMessage()
            setError(false)
        }
        setOpen(false)
        setSelected([])
        setOperation("add")
    }

    const handleClick = (event, row) => {
        let elem = selected.includes(row)
        setSelected([...selected, row])

        if (elem) {
            let jauns = selected.filter(x => x !== row)
            setSelected(jauns)
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenEdit = (id) => {
        setSelected(id)
        setOperation("edit")
        setOpen(true)
    }

    const handleOpenAdd = () => {
        setOperation("add")
        setOpen(true)
    }

    const reorder = (list, startIndex, endIndex) => {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const list = reorder(
            data,
            result.source.index,
            result.destination.index
        );
        setData(list)
        setFilteredData(list)

        axios.put(`${process.env.REACT_APP_API_URL}/pagesindex`, { list }, { withCredentials: true })
            .then(response => {
                setStatus(response.data.status)
                setMessage(response.data.message)
            }
            )

    }

    const deleteitems = () => {
        setOpenPop(true)
    }

    const closed = () => {
        setOpenPop(false)
        setSelected([])
    }

    const onOk = () => {
        setOpenPop(false)

        const array = []
        selected.forEach(x => array.push(x.id))
        axios.delete(`${process.env.REACT_APP_API_URL}/pagesdelete`, { params: { array } })
            .then(response => {
                if (response.data === "Ok") {
                    getData()
                }
            })
            .catch((error) => {
                setStatus("error")
                setMessage(error)
            })
        setSelected([])
    }

    const handleChange = (e) => {
        setSelectvalue(e.target.value)

        if (e.target.value !== -1) {
            let filtereditems = data.filter(item => item.Published === e.target.value)

            setFilteredData(filtereditems)
        }
        setPage(0)
    }

    const allvalues = (e) => {
        setFilteredData(data)
    }

    const closing = () => {
        setError(false)
    }

    const filteredsettings = settings?.languagelist?.filter(x => x !== settings?.languagemain)

    return (
        <>
            <Snackbar open={error} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={closing}>
                <Alert severity={status} sx={{ width: '100%' }} color={status}>
                    {translate(language, message)}
                </Alert>
            </Snackbar>

            <div style={{ marginBottom: "20px" }} className="toolbar">
                <h1>{translate(language, "pages")}</h1>
                <span>
                    <Button
                        variant="contained"
                        onClick={handleOpenAdd}
                        name='add'
                        color="primary"
                    >
                        <AddIcon fontSize="small" />
                        {translate(language, "new-page")}
                    </Button>

                    <Button
                        style={{ width: "auto" }}
                        onClick={() => handleOpenEdit(selected)}
                        name='edit'
                        variant="text"
                        disabled={selected?.length === 1 ? false : true}
                        color="primary"

                    >
                        <EditIcon
                            fontSize="small"
                        />
                        {translate(language, "button-edit")}
                    </Button>
                </span>
            </div>
            <div>
                <p>{translate(language, "pages-text")}</p>
            </div>


            <div style={{ marginBottom: "20px" }} >
                <PopConfirm open={openPop} close={closed} onOk={onOk} title={" "} component={translate(language, "delete-items", selected.length)} />
                <div style={{ height: selected.length >= 1 ? "0px" : "50px" }} />
                {selected.length >= 1 &&
                    <Alert
                        variant="filled"
                        severity="info"
                        icon={false}
                        action={
                            <Tooltip title="Delete" placement="top">
                                <IconButton onClick={deleteitems} size="small">
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        }
                    >
                        <b>{selected.length} {translate(language, "selected")}</b></Alert>
                }
            </div>

            <FormControl sx={{ m: 1, width: 190, height: 5, marginBottom: "20px" }} size="small" >
                <InputLabel id="demo-select-small-label">{translate(language, "header-isAdmin")}</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="statuss"
                    value={selectvalue}
                    sx={{ backgroundColor: "white" }}
                    onChange={handleChange}
                >
                    <MenuItem onClick={allvalues} value={-1}>...</MenuItem>
                    <MenuItem value={1}>{translate(language, "tag-published")}</MenuItem>
                    <MenuItem value={0}>{translate(language, "tag-draft")}</MenuItem>

                </Select>
            </FormControl>

            <TableContainer >
                <Table >
                    <TableHead
                        sx={{
                            backgroundColor: "rgb(244, 246, 248);"
                        }}
                    >
                        <TableRow >
                            {coldefs?.map(x =>
                                <TableCell
                                    key={x.field}
                                    sortDirection={false}>

                                    {translate(language, x.headerName)}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    {data?.length > 0 ?
                        <DragDropContext onDragEnd={onDragEnd} >
                            <Droppable droppableId={'1'} direction="vertical">
                                {(provided, snapshot) => (
                                    <tbody
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {(rowsPerPage > 0
                                            ? filtereditems?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : filtereditems).map(x => <Draggable key={x.id} draggableId={(x.id).toString(2)} index={data.indexOf(x)} >
                                                {(provided) => (
                                                    <TableRow ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        key={x.id}
                                                        sx={{ backgroundColor: "white" }}
                                                        selected={selected?.includes(x)}
                                                        hover
                                                        onClick={(event) => handleClick(event, x)}
                                                        onDoubleClick={() => handleOpenEdit([x])}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                value={x.id}
                                                                checked={selected.includes(x)}
                                                            />
                                                        </TableCell>
                                                        <TableCell >{x.Name}</TableCell>
                                                        <TableCell> {x.Info ? ((x.Info).split(" ", 4).join(" ") + " ...") : ""}</TableCell>
                                                        <TableCell >{x.Author}</TableCell>
                                                        <TableCell >{x.Created ? new Date(x.Created).toLocaleDateString() : ""}</TableCell>
                                                        <TableCell>{x.Published === 1 ? <span className="tag-green">{translate(language, "tag-published")}</span> : <span className="tag-red">{translate(language, "tag-draft")}</span>}</TableCell>
                                                        {filteredsettings?.map(y => <TableCell key={y}>{x?.languages?.includes(y) ? <CheckCircleOutlineIcon color="success" /> : <HighlightOffIcon color="error" />}</TableCell>)}
                                                    </TableRow>

                                                )}
                                            </Draggable>
                                            )}
                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </DragDropContext> : <NoData />}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={translate(language, "rows-per-page")}
            />
            {open && <AddPageModal handleClose={handleClose} open={open} operation={operation} selected={selected} />}
        </>
    )
}