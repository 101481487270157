import React from "react"
import "./about.css"
import fish from "../../assets/fish.png"

export default function About() {

    const fishreverse = () => {
        var element = document.getElementById("fish");

        element.classList.toggle("reverse")
    }
    return (
        <section className="about">
            <h2>samazini atkarību no izstrādātāja</h2>
            <span>
                <ul>
                    <li>Ērti pārvaldiet lapas, izmantojot vizuālās rediģēšanas rīkus.</li>
                    <li> Strukturējiet un atkārtoti izmantojiet saturu.</li>
                    <li> Saglabājiet melnrakstus un publicējiet tos vēlāk.</li>
                    <li>Nodrošiniet savas lapas saturu vairākās valodās.</li>
                </ul>
                </span>

            <img src={fish} className="fish" id="fish" alt="Fish" onClick={fishreverse} />
        </section>
    )
}