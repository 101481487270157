import React from "react"

export default function cellRenderer({ pageId, pages }) {
    let text = ""

    const page = () => {
        if(pages){
        for (let i = 0; i < pages.length; i++) {
            if (pages[i].id === pageId) {
                text = pages[i].Name
            }
        }
        return text
    }
    }
    return (
        <div>{page()}</div>
    )
}