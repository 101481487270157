import React, {useContext} from "react"
import {
    Snackbar,
    Alert
} from '@mui/material';
import translate from "../../../helpers/translate"
import LanguageContext from "../../../helpers/context"

export default function Message({status, message, func, open}){
    const {language} = useContext(LanguageContext)

    return(
        <Snackbar open={open} autoHideDuration={4000} onClose={func } anchorOrigin={{ vertical: "top", horizontal: "center" }} >
        <Alert severity={status} sx={{ width: '100%' }} color={status}>
            {translate(language, message)}
        </Alert>
    </Snackbar>
    )
}