import React, { useState, useContext } from "react"

import { Card, CardMedia, Typography, CardContent, CardActionArea, IconButton, Button } from "@mui/material"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popwindow from "../poppover"
import translate from "../../../../helpers/translate";
import LanguageContext from "../../../../helpers/context"
import PopConfirm from "../../popConfirm"
import Broken from "../../../../assets/broken.jpg"

export default function Cardphoto({ src, title, edit, id, date, getdata }) {
  const [anchorEl, setAnchor] = useState()
  const [openpopup, setOpenpopup] = useState(false)
  const [openPop, setopenPop] = useState(false)

  const { language } = useContext(LanguageContext)
  const axios = require('axios').default;


  const editview = () => {
    edit(id, title)
  }

  const popup = (e) => {
    setAnchor(e.currentTarget)
    setOpenpopup(!openpopup)
  }

  const handleClose = () => {
    setOpenpopup(false)
  }

  const dateParse = () => {

    const today = new Date()
    const created = new Date(date)
    let difference = Math.trunc((today - created) / 1000)
    let text = ""

    switch (id > 0) {
      //sekundes
      case difference < 60:
        difference = Math.floor(difference)
        text = translate(language, `date-secs`, difference)
        break
      // //zem stundas
      case difference < 3600:
difference = Math.floor(difference/60)
        text = translate(language, `date-minutes`, difference)
        break
       //stundas
       case difference < 86400:
        difference = Math.floor(difference/3600)
         text = translate(language, `date-hours`, difference)
        break
        //days
      case difference < 604800:
        difference = Math.floor(difference/86400)
        text = translate(language, `date-days`, difference)
        break
        //weeks
        case difference < 2629743:

          difference = Math.floor(difference/604800)
          text = translate(language, `date-weeks`, difference)
          break
          //months
          case difference < 31556926:
            difference = Math.floor(difference/2629743 )
            text = translate(language, `date-months`, difference)
            break
            //year
            case difference < 315360000:
              difference = Math.floor(difference/31556926)
              text = translate(language, `date-years`, difference)
              break
    }
    return text;
  }

  const openpop = () => {
    if (id) {
      setopenPop(true)
    }
  }

  const onok = () => {
    setOpenpopup(false)
    setopenPop(false)

    axios.delete(`${process.env.REACT_APP_API_URL}/gallerydelete`, { params: { id, title } })
      .then(response => {
        if (response.data === "Ok") {

          getdata()
        }
      })
  }

  const close = () => {
    setopenPop(false)
    setOpenpopup(false)
  }

  return (
    <>
      <PopConfirm open={openPop}
        component={translate(language, "question-gal", title)}
        onOk={onok}
        close={close}
      />
      <Card sx={{ width: "12rem", marginBottom: "20px" }}>
        <CardActionArea>
          <CardMedia
            image={ src ? `${process.env.REACT_APP_API_URL}/uploads/${src}` :  Broken }
            title={title}
            component="img"
            height="144"
            onClick={editview}
          />
          <CardContent>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="body1" onClick={editview}>
                {title}
              </Typography>
              <MoreHorizIcon onClick={popup} className="clickable" />
              <div className="absolute">{id}</div>
            </div>
            <Typography paragraph variant="caption" >
              {dateParse()}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Popwindow
        open={openpopup}
        anchorEl={anchorEl}
        handleClose={handleClose}
        menufunc={openpop}
        data={title} />
    </>
  )
}