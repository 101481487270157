import React from "react";
import Form from "../register/form"

export default function Register() {
   
    return (
        <div className="mainpage">
            
            <div className="login-window">
               <Form/>
            </div>
        </div>
    )
}