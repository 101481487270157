import React, { useState, useContext, useEffect } from "react"
import { FormControl, InputLabel, OutlinedInput, Button, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import image from "../../assets/logo.png"
import translate from "../../helpers/translate";
import LanguageContext from "../../helpers/context"
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm } from "react-hook-form";
import "../login/loginpage.css"
import Error from "../main/components/Message"
import { useLocation } from 'react-router-dom';
import ErrorPage from "../main/components/errorPage";
import { useNavigate } from "react-router-dom";

export default function Newpassword() {
    const { language, setLanguage } = useContext(LanguageContext)
    const [validpass, setValidpass] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit } = useForm({ defaultValues: null });
    const [status, setStatus] = useState()
    const [message, setMessage] = useState()
    const [errorpage, setErrorpage] = useState({message: 'error-msg', status: 'error'})
    const navigate = useNavigate();

    let location = useLocation();
    const axios = require('axios').default;
    const query = new URLSearchParams(location.search)
    const key = query.get('key')
   

    useEffect(() => {
        getlink()
    }, [key])

    const getlink = () => {


        axios.get(`${process.env.REACT_APP_API_URL}/getlink`, { params: { key } }, { withCredentials: true })
            .then(response =>
                setErrorpage(response.data)
            )
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const passwordStrength = (password) => {

        let set = password.split("")
        if (set.length >= 8) {
            setValidpass(true)
        } else {
            setValidpass(false)
        }
    }

    const save = (data) => {
        if (data.password !== data.password2) {
            setStatus("error")
            setMessage("no-match")
        } else {
            data.email = errorpage[0]?.username
            axios.post(`${process.env.REACT_APP_API_URL}/changepass`, { data }, { withCredentials: true })
                .then(response => {setStatus(response.data.status)
                setMessage(response.data.message)
                if(response.data.status === "success"){
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            }})
        }
    }

    if (!errorpage[0]?.username) {
        return <div className="mainpage"><ErrorPage /></div>
    }

    return (
        <div className="mainpage">
            <Error status={status} message={message} open={message ? true : false} />

            <div className="login-window">
                <div className="logo"  >
                    <img
                        src={image}
                        width="50%"
                        alt="logo"
                    />
                </div>
                <h1>{translate(language, "title-renew")}</h1>

                <form onSubmit={handleSubmit(save)} >
                    <FormControl
                        fullWidth
                        margin="normal"
                        size="small"
                        error={typeof validpass === "boolean" ? !validpass : false}
                        required
                    >
                        <InputLabel htmlFor="outlined-adornment-password-login">{translate(language, "password-login")}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password-login"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            {...register("password", {
                                onChange: (e) => { passwordStrength(e.target.value) },
                            })}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        size="large"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>

                                </InputAdornment>
                            }
                            label={translate(language, "password-login")}
                        />
                        <FormHelperText id="component-helper-text">
                            Parolei jāsatur vismaz 8 simboli
                        </FormHelperText>
                    </FormControl>


                    <FormControl
                        fullWidth
                        margin="normal"
                        size="small"
                        error={typeof validpass === "boolean" ? !validpass : false}
                        required
                    >
                        <InputLabel htmlFor="outlined-adornment-password-login2">{translate(language, "password2")}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password-login2"
                            type={showPassword ? 'text' : 'password'}
                            name="password again"
                            {...register("password2")}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        size="large"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>

                                </InputAdornment>
                            }
                            label={translate(language, "password2")}
                        />
                    </FormControl>

                    <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        margin="normal"
                    >
                        {translate(language, "submit-continue")}
                    </Button>
                </form>
            </div>
        </div>
    )
}