import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import translate from "../../helpers/translate";
import LanguageContext from "../../helpers/context"

export default function PopConfirm({open, close, onOk,  title, component }) {

  const {language} = useContext(LanguageContext)

  const handleClose = (e) => {
    close(e.target.value);
  };

  const handleok =()=>{
    onOk()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {component}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{translate(language, "button-cancel")}</Button>
          <Button onClick={handleok} autoFocus>
          {translate(language, "button-ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}