import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import "./loginpage.css"
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button, Alert } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import image from "../../assets/logo.png"
import { useNavigate } from 'react-router-dom';
import translate from "../../helpers/translate";
import LanguageContext from "../../helpers/context"
import Error from "../../components/main/components/Message"
const axios = require('axios').default;
var _ = require('lodash');

export default function Loginpage() {

    const [emails, setEmail] = useState("");
    const [passwords, setPasswords] = useState("");
    const [emptymail, setEmptyMail] = useState(false);
    const [validemail, setValidemail] = useState(false);
    const [empty, setEmpty] = useState(false);
    const [login, setLoginAlert] = useState("");
    const [isSubmitting, setSubmitting] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState()
    const [status, setStatus] = useState("success")
    const { language, setLanguage } = useContext(LanguageContext)

    const navigate = useNavigate();
  
    const path = process.env.REACT_APP_API_URL

    useEffect(() => {
        getUser()
    }, [] )

    const getUser = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/user`, { withCredentials: true })
            .then((response) => {
                if (response.data[0]?.id) {
                    setLanguage(response.data[0].language)
                    navigate("/dashboard")
                }
            })
            .catch((error) => {
                console.log("catch errors:", error)
                setMessage("Oops, serveris nestrādā! Atnāc vēlāk!")
                setStatus("error")
                setSubmitting(false)
            });
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: "#673AB7",
            },
            info: {
                main: "#eee7f6",
            },
        }
    });

    const email = (e) => {
        var regexemail = new RegExp(/[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/gm);
        setEmail(e.target.value)
        const validemail = regexemail.test(e.target.value)
        setValidemail(validemail)
    }

    const password = (e) => {
        setPasswords(e.target.value)
    }

    const signin = (e) => {
        e.preventDefault();

        const params = { emails, passwords }

        if (emails === "") {
            setEmptyMail(true)
        } else {
            setEmptyMail(false)
        }


        if (passwords) {
            setEmpty(false)
        } else {
            setEmpty(true)
        }

        if (passwords && validemail) {
            findUser(params)
        }
    }

    const findUser = (data) => {
        setSubmitting(true)

        axios.post(`${process.env.REACT_APP_API_URL}/`, { data }, { withCredentials: true })
            .then((response) => {
                if (response.data == "redirect") {
                    navigate("/dashboard/home")
                } else {
                    setLoginAlert(response.data)
                }
                setSubmitting(false)
            })
            .catch((error) => {
                setMessage("Oops, serveris nestrādā! Atnāc vēlāk!")
                setStatus("error")
                setSubmitting(false)
            });
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="mainpage">
                <Error status={status} message={message} open={message ? true : false} />
                <div className="login-window">
                    <div className="logo"  >
                        <img
                            src={image}
                            width="50%"
                            alt="logo"
                        />
                    </div>
                    <h1>{translate(language, "login-page")}</h1>
                    {login && <Alert severity="error">{translate(language, login)}</Alert>}
                    <form onSubmit={signin}>
                        <FormControl fullWidth margin="normal"
                            error={emails ? !validemail : emptymail}
                        >
                            <InputLabel htmlFor="outlined-adornment-email-login">{translate(language, "email-login")}</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                value={emails}
                                name="email"
                                onChange={email}
                                label="Email Address"
                                data-testid="login-email"
                            />
                        </FormControl>

                        <FormControl fullWidth margin="normal"
                            error={empty}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">{translate(language, "password-login")}</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={passwords}
                                name="password"
                                onChange={password}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                margin="normal"
                                color="primary"
                            >
                                {translate(language, "login")}
                            </Button>
                        </FormControl>
                    </form>
                    <span className="register">
                        <Link to="/register">{translate(language, "login-register")}</Link>
                        <Link to="/renewPassword">{translate(language, "login-forgot")}</Link>
                    </span>
                </div>
            </div>
        </ThemeProvider>
    )

}