import React, { useState, useEffect, useContext } from "react"
import axios from "axios";
import {
  Button,
  MenuItem,
  TablePagination,
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Avatar,
  Alert,
  Snackbar,
  TableSortLabel,
  FormControl,
  Select,
  InputLabel
} from '@mui/material';
import Form from "../../../register/form"
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LanguageContext from "../../../../helpers/context"
import Popup from "../poppover"
import NoData from "../noData";
import translate from "../../../../helpers/translate"
import UserPic from "../../../../assets/system-regular-8-account (1).png"

import "./userGrid.css"

export default function UserGrid() {

  const [data, setData] = useState([])
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0)
  const [anchorEl, setAnchor] = useState()
  const [openpopup, setOpenpopup] = useState(false)
  const [colDefs, setColdefs] = useState()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [user, setUser] = useState()
  const [error, setError] = useState()
  const [sortStatus, setSortStatus] = useState()
  const [sortOrder, setSortOrder] = useState("")
  const [selectvalue, setSelectvalue] = useState(-1)
  const [filtereditems, setFilteredData] = useState()
  const [status, setStatus] = useState()
  const { language } = useContext(LanguageContext)


  const urlParams = new URLSearchParams(window.location.search);
  const id  = urlParams.get('id');

  useEffect(() => {
    getData()
    getColDefs()
    getUser()
    if(id){
      setSelected(Number(id))
        }
  }, []);


  const getData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/users`, { withCredentials: true })
      .then(response => {
        setData(response.data)
        setFilteredData(response.data)
      
      })
      .catch((error) => {
        setError(error)
      })

  }

  const getUser = () => {

    axios.get(`${process.env.REACT_APP_API_URL}/user`, { withCredentials: true })
      .then((response) => {
        if (response.data) {
          setUser(response.data)
        }
      })
  }

  const getColDefs = () => [
    axios.get(`${process.env.REACT_APP_API_URL}/userColDefs`)
      .then(response => 
        setColdefs(response.data))
  ]

  const handleOpen = () => setOpen(true);

  const handleClose = (data) => {

    getData()
    setError(data.message)
    setStatus("success")
    setOpen(false)
    setOpenpopup(false)
  }

  const style = {
    width: "auto",
    height: "auto",
    margin: "auto auto 60px auto"
  }

  //popup for delete option
  const popup = (e) => {
    setAnchor(e.currentTarget)
    setOpenpopup(!openpopup)

  }

  const deleteUser = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/userdelete`, { selected }, { withCredentials: true })
      .then(response => {
        setError(response.data.message)
        getData()
      })

    setOpenpopup(false)
  }

  //set selected row for anchor popup
  const handleClick = (event, id) => {
    setSelected(id)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (field) => {
    setSortStatus(field)
    setSortOrder("asc")

    data.sort((a, b) => a[field].localeCompare(b[field]));

    if (sortStatus === field) {
      if (sortOrder === "asc") {
        setSortOrder("desc")
        data.sort((a, b) => b[field].localeCompare(a[field]));
      } else {
        setSortOrder("asc")
      }
    }
  }

  const handleChange = (e) => {
    setSelectvalue(e.target.value)

    switch (e.target.value) {
      case 1:
        let filteredadata = data.filter(item => item.isAdmin === e.target.value)
        setFilteredData(filteredadata)
        break
      case 0:
        let draft = data.filter(item => item.isAdmin === undefined)
        setFilteredData(draft)
        break
      case -1:
        allvalues()
        break
      default:
        setFilteredData(data)
    }

  }

  const allvalues = (e) => {
    setFilteredData(data)
  }

  if (!user) {
    return
  }

  return (
    <>
      {error && <Snackbar open={error ? true : false} autoHideDuration={4000} onClose={() => { setError() }} anchorOrigin={{ vertical: "top", horizontal: "center" }}><Alert severity={error.status}>{translate(language, error)}</Alert></Snackbar>}
      <div style={{ marginBottom: "20px" }} className="toolbar">
        <h1>{translate(language, "users")}</h1>
        <Button
          variant="contained"
          onClick={handleOpen}
          color="primary"
        >
          <PersonAddIcon fontSize="small" sx={{ marginRight: "5px" }} />
          {translate(user[0].language, "button-add-user")}
        </Button>
      </div>

      <FormControl sx={{ m: 2, width: 190 }} size="small" >
        <InputLabel id="demo-select-small-label">{translate(language, "header-isAdmin")}</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          label="statuss"
          value={selectvalue}
          sx={{ backgroundColor: "white" }}
          onChange={handleChange}
        >
          <MenuItem value={-1}>...</MenuItem>
          <MenuItem value={1}>{translate(language, "select-admin")}</MenuItem>
          <MenuItem value={0}>{translate(language, "select-user")}</MenuItem>

        </Select>
      </FormControl>

      <TableContainer  >
        <Table >
          <TableHead >
            <TableRow >
              {colDefs?.map(x =>
                <TableCell
                  key={x.field}
                  sortDirection={false}
                >
                  <TableSortLabel
                    active={sortStatus === x.field}
                    direction={sortStatus === x.field ? sortOrder : "asc"}
                    onClick={(e) => { if (x.field !== "options" & x.field !== "isAdmin") { createSortHandler(x.field) } }}
                    hideSortIcon={x.field === "options" || x.field === "isAdmin" ? true : false}
                  >
                    {translate(language, x.headerName)}
                  </TableSortLabel>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          {data.length > 0 ? <TableBody>
            {(rowsPerPage > 0
              ? filtereditems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filtereditems).map(x => <TableRow key={x.id} hover sx={{ backgroundColor: "white" }} selected={selected === x.id ? true : false} onClick={(event) => handleClick(event, x.id)}  >
                <TableCell ><span className="gridAvatar"><Avatar
                  src={x.file ? process.env.REACT_APP_API_URL + x.file : UserPic}
                  sx={{ width: 27, height: 27 }}
                  style={{ marginRight: "10px" }}
                />{x.name} {x.surname}</span></TableCell>
                <TableCell >{x.username}</TableCell>
                <TableCell >{x.dateofcreate === null ? "" : new Date(x.dateofcreate).toLocaleDateString()}</TableCell>
                <TableCell >{<span className={x.isAdmin ? "tag-green" : "tag-red"} >{x.isAdmin === 1 ? translate(language, "select-admin") : translate(language, "select-user")}</span>}</TableCell>
                {user[0]?.isAdmin ? <TableCell >{x.isAdmin ? "" : <IconButton size="small" className="clickable" onClick={popup}><MoreVertIcon /></IconButton>}</TableCell> : ""}
              </TableRow>)}
          </TableBody> : <NoData />}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={translate(language, "rows-per-page")}

      />

      <Dialog
        sx={style}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={style}>
            <Form domain={user[0]?.domain} close={handleClose} />
          </Box>
        </DialogContent>
      </Dialog>
      <Popup open={openpopup} anchorEl={anchorEl} handleClose={handleClose} menufunc={deleteUser} />
    </>
  )
}