import '../../App.css';
import About from './components/about/about';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import Main from './components/main';
import Video from './components/video/video';

export default function Landing() {
  return (
    <div className='land'>
<Header/>
<Main/>
<About/>
<Video/>
<Footer/>
</div>
  );
}