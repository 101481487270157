import React, { useEffect, useState, useContext } from "react";
import "./main.css"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SearchOn from "./search"
import LanguageContext from "../../helpers/context"
import translate from "../../helpers/translate";
import UserPic from "../../assets/system-regular-8-account (1).png"
import Avatar from '@mui/material/Avatar';

import {
  Drawer,
  Menu,
  Button,
  MenuItem,
  Box
} from '@mui/material';

import Sidebar from "./sidebar"
import Message from "./components/Message";
import ErrorPage from "./components/errorPage";

const axios = require('axios').default;

export default function Main() {

  const [user, setUser] = useState({})
  const [anchorEl, setAnchorEl] = useState(null);
  const [menu, setMenu] = useState([])
  const [message, setMessage] = useState()
  const [status] = useState("error")
  const { language, setLanguage } = useContext(LanguageContext)
  var _ = require('lodash');

  const navigate = useNavigate();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#673AB7",
        dark: "#573491",
    
      },
      info: {
        main: "#d3eafe",
        dark: "#155a92"
      },
      success: {
        main: "#69F0AE"
      },
      warning: {
        main: "#FFE57F"
      },
      error: {
        main: "#F44336"
      }
    }

  });

  useEffect(() => {
    getUser()
  }, []);

  const getMenu = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/getMenu`, { withCredentials: true })
      .then((response) => {
        setMenu(response.data)
      })
  }

  const getUser = () => {

    axios.get(`${process.env.REACT_APP_API_URL}/user`, { withCredentials: true })
      .then((response) => {
        if (_.isArray(response.data)){
          setUser(response.data)
         setLanguage(response.data[0].language )
         getMenu()
        } else {
          navigate("/login")
        }
      })
      .catch((error) => {
       setMessage("")
      });
  }

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (user[0] === undefined) {
    return <div />
  }

  const logoff = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/logoff`, { withCredentials: true })
      .then((response) => {
        navigate("/login")

      })
      .catch((error) => {
        setMessage("error-msg")
      });
  }

  const closemessage=()=>{
    setMessage()
  }

  if (!user[0].id)
  return <ErrorPage/>

  return (
    <ThemeProvider theme={theme}>
      <Message open={message ? true : false} func={closemessage} message={message} status={status}/> 
      <div className="flex">

        <Drawer
          sx={{
            width: 200,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 200,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Box>
            <Sidebar menu={menu} />
          </Box>
        </Drawer>

        <div className="main" style={{ flexGrow: 1, }}>

          <div className="searchbar">
            <SearchOn theme={theme} />
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <Avatar
                src={user[0].file ? process.env.REACT_APP_API_URL + user[0].file : UserPic}
                sx={{ width: 27, height: 27 }}
                style={{ marginRight: "10px" }}
              />

              <div className="userName">
                {user[0].name}
              </div>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                component={Link} // <-- pass Link as component
                to="/dashboard/user"
              >
                {translate(language,"profile")}
              </MenuItem>
              <MenuItem onClick={logoff}>{translate(language, "logout")}</MenuItem>
            </Menu>
          </div>
          <div className="render"> 
           <Outlet /> 
          </div>
          <span>© TrakaMeitene, 2023</span>
        </div>

      </div>
    </ThemeProvider>

  )
}